body{
    background-color: rgb(45, 45, 45);
}

.youtube{
    display:flex;
    gap:20px;
}

.youtube-card {
    margin: 10px;
    width: 300px; /* Adjust the width as needed */
    height: 180px; /* Adjust the height as needed */
  }
  
  .thumb {
    width: 100%;
    height: 100%;
  }
  
  .title {
    font-size: 16px;
    font-weight: bold;
    color: white;
    padding-bottom:10px ;
  }
  
  .channel {
    font-size: 14px;
    color: white;
  }
  
  .views_time {
    font-size: 14px;
    color: white;  
}
  
  .views_time span {
    padding: 10px;
    color: white;  
}
  